import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseNotice = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Solved Sports LLC may notify users with respect to the Website by sending an e-mail message to
            the e-mail address provided by the user or by a posting on the Website. Notices are effective
            immediately, whether or not the user receives actual notice of the changes.
        </Typography>
    </Box>
)

export default TermsOfUseNotice;