import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyInformationAccess = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Your personal information is accessible only by designated Solved Sports LLC employees,
            including administrative staff. In limited cases, personal information related to site functionality
            may be visible to our designated web service provider.
            <br/>
            <br />
            Any information you share on your public profile through your use of the Website or Services
            will be visible to other Solved Sports LLC users.
        </Typography>
    </Box>
)

export default PrivacyPolicyInformationAccess;