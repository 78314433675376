import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseContent = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            During your use of our Website and Services, you'll encounter: (i) “Your Content,” which refers
            to the content you upload or provide; (ii) “User Content,” referring to content uploaded or provided
            by other users. Within this agreement, "content" encompasses, but is not limited to, text, images,
            video, audio, or any other material found on our Services, including information within users'
            profiles and messages posted on the Website or exchanged between users.
            <h5>Your Content</h5>
            You bear sole responsibility and liability for Your Content, and you agree to indemnify, defend,
            release, and absolve Solved Sports LLC from any claims arising from Your Content. You
            understand and acknowledge our right to monitor or review Your Content, and we reserve the right
            to remove, edit, or restrict access to Your Content at our discretion. You understand and
            acknowledge that we have no obligation to display or review Your Content.
            The User agrees and represents that any uploaded content is created, owned, or licensed for use by
            the user. Users may not upload content that contains third-party copyrighted material, or material
            that is subject to other third-party rights, without permission from the rightful owner of the
            material.
            <br/>
            <br/>
            You acknowledge that any materials uploaded to the Website may be subject to posted limits on
            use, reproduction, and/or dissemination, and you are responsible for abiding by such limitations
            with respect to your submissions.
            <br />
            <br />
            Solved Sports LLC claims no ownership, does not assume any liability, and does not endorse nor
            guarantee the accuracy or propriety of any of the information its users submit through the Website.
            <br />
            <br />
            The User acknowledges that Solved Sports LLC has no obligation to pre-screen or monitor
            materials uploaded through the Website.
            <h5>User Content</h5>
            Other Solved Sports LLC Users share their own content on our Services. User Content is the
            property of the user who posted it and is stored on our servers and displayed at their direction. You
            don't possess any rights regarding User Content, and unless Solved Sports LLC explicitly
            authorizes it, you can only use User Content within the scope of our Services' purpose, which is to
            facilitate communication and connection among users. Using User Content for commercial gain,
            spamming, harassing, or making unlawful threats is strictly prohibited. Misuse of User Content
            may result in the termination of your account.
        </Typography>
    </Box>
)

export default TermsOfUseContent;