import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseIndemnification = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            To the fullest extent allowed by applicable law, you agree to indemnify and hold Solved Sports
            LLC, its affiliates, officers, agents, employees, and partners harmless from and against any and all
            claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’
            fees) arising from or in any way related to any third party claims relating to (a) your use of our
            Website and Services (including any actions taken by a third party using your account), and (b)
            your violation of these Terms.
        </Typography>
    </Box>
)

export default TermsOfUseIndemnification;