import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyInformationUsage = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            You authorize us to use the Personal Information, Payment Information, and the Other
            Information (collectively, the "Information") to: provide and improve our Website; provide our
            services; administer our promotional programs; solicit your feedback; and inform you about our
            products and services.
            <br />
            <br />
            In order to provide our services and administer our promotional programs, we may share the
            Information with our third-party promotional and marketing partners, including, without
            limitation, businesses participating in our various programs.
            <br />
            <br />
            We engage third-party companies and individuals to perform functions on our behalf. Examples
            may include providing technical assistance, customer service, marketing assistance, and
            administration of promotional programs. These other companies will have access to the
            Information only as necessary to perform their functions and to the extent permitted by law.
            <br />
            <br />
            In an ongoing effort to better understand our users, the Website, and our products and services,
            we may analyze certain Information in anonymized and aggregate form to operate, maintain,
            manage, and improve the Website and/or such products and services. This aggregate information
            does not identify you personally. We may share and/or license this aggregate data to our
            affiliates, agents, business and promotional partners, and other third parties. We may also
            disclose aggregated user statistics to describe the Website and these products and services to
            current and prospective business partners and investors and to other third parties for other lawful
            purposes.
            <br />
            <br />
            We may share some or all of your Information with any of our parent companies, subsidiaries,
            joint ventures, or other companies under common control with us. As we develop our businesses,
            we might sell or buy businesses or assets. In the event of a corporate sale, merger,
            reorganization, sale of assets, dissolution, or similar event, the Information may be part of the
            transferred assets.
            <br />
            <br />
            To the extent permitted by law, we may also disclose the Information:
            <ul>
                <li>When required by law, court order, or other government or law enforcement authority or
                    regulatory agency; or</li>
                <li>Whenever we believe that disclosing such Information is necessary or advisable, for
                    example, to protect the rights, property, or safety of us or others, including you.</li>
            </ul>
        </Typography>
    </Box>
)

export default PrivacyPolicyInformationUsage;