import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyChanges = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. Solved Sports
            LLC retains the right to update this Privacy Policy whenever we deem fit. Please revisit our Privacy
            Policy occasionally to review any changes. If we make material changes to the Privacy Policy, we
            will provide appropriate notice to you. Your continued use of the Services means that you accept
            these changes.
        </Typography>
    </Box>
)

export default PrivacyPolicyChanges;