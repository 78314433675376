import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyRights = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            If you have provided us any Personal Information, you may access, remove, review, and/or make
            changes to the same by contacting us as set forth below. We will use commercially reasonable
            efforts to process such requests in a timely manner. You should be aware however, that it is not
            always possible to completely remove or modify information in our subscription databases. You
            cannot opt out of receiving transactional emails related to the Website and our Services.
        </Typography>
    </Box>
)

export default PrivacyPolicyRights;