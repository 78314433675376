import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseContact = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            In order to resolve a complaint regarding Solved Sports LLC or to receive further information
            regarding use of our Website or Services, please contact us at <a href='info@solvedsports.com'>info@solvedsports.com</a>.
        </Typography>
    </Box>
)

export default TermsOfUseContact;