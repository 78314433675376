import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyInformationRetainment = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Your information will only be retained as long as reasonably necessary for the purposes set out in
            this privacy policy, and in accordance with applicable law.
        </Typography>
    </Box>
)

export default PrivacyPolicyInformationRetainment;