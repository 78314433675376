import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseAccountEligibility = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Users are not authorized to create an account with Solved Sports LLC and use Solved Sports LLC’s
            services unless all of the following are true: (1) You are at least 18 years old OR if you are under
            the age of 18, that you obtained a parent or legal guardian’s consent before accessing the Website
            and/or using our Services; (2) you are qualified to enter a binding contract with the Company; (3)
            you are not prohibited by law from using our Services; (4) you do not have more than one account
            on our Services; and (5) you have not previously been removed from our Services.
            <br/>
            <br/>
            By using Solved Sports LLC’s Services, you represent and warrant that all of the above are true.
            If at any time you cease to meet these requirements, all authorization to access our Website and
            Services is automatically revoked, and you must immediately delete your account, and we retain
            the right to remove your access to our services without warning.
        </Typography>
    </Box>
)

export default TermsOfUseAccountEligibility;