import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseLimitedLiability = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            THE USER EXPRESSLY UNDERSTANDS AND AGREES THAT SOLVED SPORTS LLC
            AND ITS SUBSIDIARIES AND AFFILIATES SHALL NOT BE LIABLE TO THE USER
            UNDER ANY THEORY OF LIABILITY (WHETHER CONTRACT, OR TORT, INCLUDING
            NEGLIGENCE OR OTHERWISE) FOR ANY DAMAGES, INCLUDING LOSS OF INCOME,
            BUSINESS, OR PROFITS THAT MAY BE INCURRED BY THE USER THROUGH USE OF
            THE SERVICE, ANY SERVICES OR CONTENT DOWNLOADED, OR OTHERWISE
            OBTAINED FROM THE SERVICE, INCLUDING ANY LOSS OF DATA. IN ANY EVENT,
            SOLVED SPORTS LLC’S LIABILITY SHALL NEVER EXCEED ONE HUNDRED USD
            ($100.00).
        </Typography>
    </Box>
)

export default TermsOfUseLimitedLiability;