import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUsePrivacyPolicy = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Solved Sports LLC’s Privacy Policy discusses how we collect, process, and disclose personal
            information. Please read this policy carefully.
        </Typography>
    </Box>
)

export default TermsOfUsePrivacyPolicy;