import ReactGA from 'react-ga4';
import React from "react";
import logo from "./SolvedSportsLogo.png";
import { Footer } from "./footer"
import { Header } from './header';

function MainPage() {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Home",
    });
    
    return (
        <>
            <div className="App">
            <header className="App-header">
            <img src={logo}/>
            <br />
            <br />
            <br />
            <Header />
            <h3>
                Our site is coming soon! Stay up to date on our progress by joining <a href='https://discord.gg/WRWZrxPBF9'>our Discord server</a>!
            </h3>
            <h4>
                <em>At Solved Sports, Our Models Watch Every Game So You Don't Have To</em>
            </h4>
            </header>
            <Footer />
            </div>       
        </>
    )
}

export default MainPage;