import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseIPLL = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Solved Sports LLC, and its partners and/or licensors, own the Website and all Content provided
            (other than Your Content and User Content) (“Site Content”) and all intellectual property rights
            relating to the Site and Site Content worldwide. All Site Content included as part of the Service,
            such as trademarks, text, graphics, images, software, and any compilation thereof is the sole property of their respective owners. Solved Sports LLC’s name and logo, and all related names,
            logos, product and service names, designs, and slogans are trademarks of Solved Sports LLC, its
            affiliates, or licensors.
            <br/>
            <br/>
            You do not acquire any ownership rights in any content. Solved Sports LLC does not grant you
            any licenses, express or implied, to the intellectual property of Solved Sports LLC or our licensors
            except as expressly authorized by these Terms.
            <br />
            <br />
            Subject to you complying with these Terms, you are granted a limited, non-transferable, non-
            sublicensable, non-exclusive, revocable license to access the Solved Sports LLC’s proprietary
            software (the “Software”). You are granted this license to access the Software, and Solved Sports
            LLC enables you to access and use the Software, subject to the other applicable provisions,
            limitations and restrictions in these Terms.
            <br />
            <br />
            You may not upload or republish the Software or software content on any Internet, intranet or
            extranet sites or incorporate the information in any other database or compilation in any monetized
            post or upload or republish the Software or software content in any manner for which you receive
            compensation or that violates any other provision of these Terms.
            <br />
            <br />
            You acknowledge and agree that all content, design elements, and materials available on the
            Website, including, without limitation, the editorial coding and metadata contained therein,
            (“Solved Sports LLC Content”) are owned by Solved Sports LLC and/or its partners and licensors
            and protected by copyrights, trademarks, trade secrets, and/or other proprietary rights of Solved
            Sports LLC and/or its partners and licensors. Solved Sports LLC and its partners and licensors
            retain all right, title and interest in and to the Solved Sports LLC Content. You may not use, copy,
            reproduce, modify, republish, upload, post, transmit or distribute any Solved Sports LLC Content
            in any form or for any means, unless expressly permitted in these Terms.
            Solved Sports LLC’s trademarks and trade dress may not be used, including as part of trademarks
            and/or as part of domain names, in connection with any product or service in any manner that is
            likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the
            prior written permission of Solved Sports LLC.
            <br />
            <br />
            You may not sell, license, rent, perform, display, create derivative works from, or in any way use
            or exploit Solved Sports LLC Content in any way unless expressly permitted in this Agreement.
            You agree not to: (a) disassemble, decompile or reverse engineer any software or other component
            of the Software or Solved Sports LLC Content; (b) examine the Software with debugging, memory
            inspection, or disk inspection tools; (c) rent or sublicense the Software; (d) permit use of the
            Software by a person who is not a registered User; (e) transmit an electronic copy of the Software
            by any means; or (f) use the Software in the operation of a service bureau or time sharing
            arrangement, or to provide outsourcing services. To the extent any such disassembly,
            decomplication or reverse engineering is applicable under the law for error correction or otherwise,
            you agree that you shall submit a written request to engage in such activity for a legally recognized
            purpose and Solved Sports LLC shall have a reasonable period of time (not to exceed 30 calendar days) to rectify such issue prior to any such disassembly, decompilation or reverse engineering
            which shall be for the sole purpose and duration of effectuating any required error correction.
            <br />
            <br />
            Unless explicitly stated herein, nothing in these Terms shall be construed as conferring to you any
            license to Solved Sports LLC Content. You agree not to assert against Solved Sports LLC any
            claim, action or proceeding for infringement of any intellectual property rights.
            You hereby grant Solved Sports LLC a non-exclusive, royalty-free, and non-transferable license
            to use, copy, store, modify, and display Personal Data as necessary to provide the Service in
            accordance with this Agreement. Personal Data submitted by User to the Service, remains the sole
            property of the User and User reserves all right, title, and interest in the Personal Data.
            Notwithstanding any other provision in this Agreement, Solved Sports LLC may collect and
            provide certain User registration and statistical information, such as usage or User traffic patterns,
            in aggregate and anonymized form to third parties, provided that such information does not identify
            any User and contains no personally identifying information. Solved Sports LLC may access
            User’s accounts, including, without limitation, Personal Data, to the extent necessary to respond
            to service or technical problems.
            <br />
            <br />
            You hereby grant Solved Sports LLC a non-exclusive, non-transferable, and royalty-free license
            to use User Marks for the limited purposes set forth in this Agreement, including as necessary for
            <br />
            <br />
            Solved Sports LLC’s performance under this Agreement, to deliver the Service in accordance with
            this Agreement, and in marketing materials related to the Service.
            <br />
            <br />
            Solved Sports LLC will have the right to use, act upon, and freely exploit any suggestion, idea,
            enhancement request, feedback, recommendation, or other information provided by User or any
            third party acting on User’s behalf, without any remuneration, fee, royalty, or expense of any kind,
            and Solved Sports LLC will hereby own all rights, title, and interest in any such suggestion, idea,
            enhancement request, feedback, recommendation, or other information provided by User or any
            third party acting on User’s behalf.
        </Typography>
    </Box>
)

export default TermsOfUseIPLL;