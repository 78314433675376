import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let themeDark = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#122333',
            paper: '#172b3c',
            alternate_paper: '#384654',
            missing: 'rgb(57, 74, 88, 0.3)'
        },
        primary: {
            main: '#1973e1',
            missing: 'rgb(57, 74, 88)'
        },
        secondary: {
            main: '#2dac68',
        },
        successVariant: {
            main: '#2dac68'
        },
        missing: {
            main: 'rgb(57, 74, 88, 0.3)'
        }
    },
    typography: {
        caption: {
            fontSize: 10,
        }
    },
});

themeDark = responsiveFontSizes(themeDark);

export default themeDark;