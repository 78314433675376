import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseSubscriptions = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            The Services provided are offered on a subscription basis (“Subscription(s)”). To process the
            payment for your Subscription, a valid payment method is required. You must provide Solved
            Sports LLC, or any third-party payment processor, with complete and accurate payment
            information (“Payment Information”). How Solved Sports LLC will use this Payment Information
            is explained in the Privacy Policy. Please read the Privacy Policy carefully. By submitting this
            Payment Information, you authorize Solved Sports LLC and our third-party payment processor to
            charge all Subscription fees incurred on your account to the payment method you have provided.
            <br/>
            <br/>
            The frequency of Payment will be determined by the type of subscription plan you select when
            purchasing a Subscription - either monthly or annually. Your subscription will automatically renew
            at the end of each payment cycle under the exact same conditions unless you cancel your
            Subscription or Solved Sports LLC cancels your subscription.
            <br />
            <br />
            If automatic payment fails for any reason, Solved Sports LLC will issue an electronic invoice
            indicating that you must manually proceed with the full payment corresponding to the billing
            period to avoid cancellation of your Subscription.
            <h5>Fee Changes and Refunds</h5>
            Solved Sports LLC reserves the right to modify our Subscription fees at any time for any reason.
            Any changes to the Subscription fee will be effective at the end of the current payment cycle. Prior
            notice of changes to Subscription fees will be sent to the email address you have provided to Solved
            Sports LLC. Your continued use of the Service after a change in Subscription fee means you accept
            the updated Subscription fee.
            <br/>
            <br/>
            We do not guarantee refunds for Subscriptions. Any refunds granted will be given at the discretion
            of Solved Sports LLC.
        </Typography>
    </Box>
)

export default TermsOfUseSubscriptions;