import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseLinks = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            We understand that our users may find it helpful to access third-party websites or services through
            our Services, including through sportsbook affiliate links. Solved Sports LLC does not own or
            control these third-party websites or services, and we are not responsible for their content, privacy
            policies, or practices. We do not endorse or guarantee the offerings of any third-party entities or
            individuals, or their websites. Solved Sports LLC does not engage in sports betting or take bets of
            any kind. By using our Services, you acknowledge and agree that we shall not be responsible or
            liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
            connection with the use of or reliance on any content, goods, or services available through any
            such third-party websites or services. We strongly advise you to review the terms and conditions
            and privacy policies of any third-party websites or services that you visit. Your use of these
            websites or services is at your own risk, and we cannot guarantee their security or reliability.
        </Typography>
    </Box>
)

export default TermsOfUseLinks;