import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseAgreement = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            These Terms constitute the entire agreement between you and Solved Sports LLC regarding your
            use of our Website and Services and supersede all prior written and oral agreements.
        </Typography>
    </Box>
)

export default TermsOfUseAgreement;