import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyInformationCollect = () => (
    <Box sx={{p:5}}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            In the course of operating the Website and providing our Services, we will collect and/or receive
            the following types of information. You authorize us to collect and/or receive such information.
            <ul>
                <li>Personal Information: We only receive or collect information that identifies youpersonally if you choose to provide such personally identifiable information to us via
                    email or other means. When you sign up to become a user or contact us, you will be
                    required to provide us with personal information about yourself (collectively, the
                    “Personal Information”). Such Personal Information may include your name, e-mail
                    address, username, and password. We do not collect any Personal Information from you
                    when you use the Website unless you provide us with the Personal Information
                    voluntarily.</li>
                <li>Payment Information: If you choose to make a purchase or subscribe to a feature or
                    service that requires a fee, you will be required to provide us with your payment
                    information, including, without limitation, bank account numbers, credit card or debit
                    card numbers, account details, ACH information, and similar data (collectively,
                    “Payment Information”). Such Payment Information will be collected and processed by
                    our third-party payment vendors pursuant to the terms and conditions of their privacy
                    policies and terms of use, and we do not obtain access to any Payment Information in
                    connection with such purchases or subscriptions</li>
                <li>Other Information: In addition to Personal Information and Payment Information, we
                    may automatically collect or receive additional information regarding you and your use
                    of the Website; your interactions with us and our advertising; and information regarding
                    your computer and mobile devices used to access the Website (collectively, the “Other
                    Information”).</li>
            </ul>
        </Typography>
    </Box>
)

export default PrivacyPolicyInformationCollect;