import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseGoverningLaw = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            To the maximum extent permitted by law, this agreement is governed by the laws of the
            Commonwealth of Massachusetts, and you hereby consent to the exclusive jurisdiction and venue
            of courts in Massachusetts in all disputes arising out of or relating to the use of the Website. Use
            of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these
            Terms, including, without limitation, this section.
        </Typography>
    </Box>
)

export default TermsOfUseGoverningLaw;