import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyHome = () => (
    <Box sx={{p:5}}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            The Solved Sports LLC Privacy Policy discusses and governs the collection and use of any
            information that is submitted to, or collected by, Solved Sports LLC. This Privacy Policy applies
            to the Solved Sports LLC website (the “Website”), owned and operated by Solved Sports LLC
            (“the Company,” “we,” “us,” or “our”). This Privacy Policy does not address the privacy
            practices of any third parties that we do not own, control, or are affiliated with. By visiting
            and/or using our Website and Services, you agree to the terms of this Privacy Policy and the
            accompanying Terms. Please read the Solved Sports LLC Privacy Policy to help you make
            informed decisions.
        </Typography>
    </Box>
)

export default PrivacyPolicyHome;