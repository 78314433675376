import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseTermination = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            These Terms will continue to apply until terminated by the User or Solved Sports LLC. Any User
            who wishes to withdraw their acceptance of these Terms and/or delete their account may do so by
            notifying Solved Sports LLC via email at help@solvedsports.com. Solved Sports LLC, in its sole
            discretion, may terminate access to the Services if the User has violated any provision of these
            Terms, the Privacy Policy, or any other policy provided to users by Solved Sports LLC governing
            the use of the Website or the Services; (ii) if Solved Sports LLC is required to do so by law; or
            (iii) if Solved Sports LLC elects to no longer provide the Website or Services.
            <br/>
            <br/>
            Further, Solved Sports LLC has the right to:
            <ul>
                <li>Remove any user account for any reason in our sole discretion;</li>
                <li>Take appropriate legal action, including without limitation referral to law enforcement, for
                    any illegal or unauthorized use of Solved Sports LLC’s Website or Services; and</li>
                <li>Terminate or suspend your access to all or part of our Services for any reason, including
                    violations of these Terms, violation of any applicable laws or to protect the safety or
                    property of other users, Solved Sports LLC, or third parties.</li>
            </ul>
            Upon receipt of a User’s request to delete their account, and except as set forth below, Solved
            Sports LLC will remove the account and the associated information within a reasonable amount
            of time. Please note that any information you have submitted to publicly accessible areas may not
            be removable.
        </Typography>
    </Box>
)

export default TermsOfUseTermination;