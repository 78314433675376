import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyContact = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            If you have questions or concerns about this Privacy Policy, please contact us
            at <a href="info@solvedsports.com">info@solvedsports.com</a>.
            <br/>
            <br/>
            Please provide sufficient information that allows us to verify whether you are the person about
            whom we collected personal information or an authorized representative. We endeavor to respond
            to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90
            days), we will inform you of the reason and extension period in writing.
        </Typography>
    </Box>
)

export default PrivacyPolicyContact;