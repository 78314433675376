import ReactGA from 'react-ga4';
import { ThemeProvider } from '@mui/material/styles';
import themeDark from './styles/theme';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from "./main-page"
import PrivacyPolicy from './privacy-policy';
import TermsOfUse from './terms-of-use';
import './App.css';

function App() {
  ReactGA.initialize('G-ZJSLW8V4Q5');
  return (
    <ThemeProvider theme = { themeDark } >
      <CssBaseline />
      <Router>
        <Routes>
        <Route path="/" element={<MainPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
