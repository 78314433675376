import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseModification = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Solved Sports LLC may modify these Terms at any time and without notice. By continuing to
            access or use Solved Sports LLC and its Services, you accept and agree to be bound by any
            modified Terms.
        </Typography>
    </Box>
)

export default TermsOfUseModification;