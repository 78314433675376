import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseWarranties = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            THE USER EXPRESSLY UNDERSTANDS AND AGREES THAT USE OF THE WEBSITE
            AND SERVICES, OR CONTENT DOWNLOADED OR OTHERWISE OBTAINED
            THROUGH THE USE OF IT, IS AT THE USER’S SOLE RISK AND ARE PROVIDED ON AN
            “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER
            EXPRESSED OR IMPLIED; INCLUDING ANY IMPLIED WARRANTIES, TERMS AND
            CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT, WITH RESPECT TO ANY
            SERVICES OR CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
            WEBSITE, AND THE WEBSITE ITSELF, TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW.
            <br/>
            <br/>
            USE OF THE WEBSITE, SERVICES, OR CONTENT DOWNLOADED, OR OTHERWISE
            OBTAINED THROUGH THE USE OF IT, IS AT THE USER’S OWN DISCRETION AND
            RISK, AND THE USER IS SOLELY RESPONSIBLE FOR ANY AND ALL DAMAGE TO THE
            USER’S COMPUTER OR ELECTRONIC DEVICE(S), OR LOSS OF DATA THAT RESULTS
            FROM SUCH USE.
        </Typography>
    </Box>
)

export default TermsOfUseWarranties;