import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyCookies = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Our Website makes use of cookies which are small digital files that are stored in your web
            browser that enable us to track your return visits to our website. Your mobile device settings may
            allow you to block these cookies, but we recommend you have them enabled to help us personalize your experience on our website. Additionally, third party advertisers on our site may
            use cookies for tracking purposes.
        </Typography>
    </Box>
)

export default PrivacyPolicyCookies;