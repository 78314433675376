import {
    Avatar,
    Box,
    IconButton,
    Stack,
    Typography,
    Link
} from '@mui/material';

import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const socialLinks = [
    {
        Icon: <InstagramIcon sx={{color: "white"}} />,
        link: 'https://www.instagram.com/solved_sports'
    },
    {
        Icon: <XIcon  sx={{color: "white"}} />,
        link: 'https://x.com/solvedsports'
    },
    {
        // No tik tok logo in MUI so using our own
        Icon: <Avatar src={require("./assets/tiktok-white-icon.png")} sx={{color:"white",width: 25,height: 25}} />,
        link: 'https://www.tiktok.com/@solvedsports'
    },
    {
        Icon: <LinkedInIcon sx={{color: "white"}} />,
        link: 'https://www.linkedin.com/company/solved-sports/'
    }
];

// const openTermsOfUse = () => {
//     window.open(require('./assets/solved-sports-terms-of-use-v1.pdf'), '_blank');
// };
// const openPrivacyPolicy = () => {
//     window.open(require('./assets/solved-sports-privacy-policy-v1.pdf'), '_blank');
// };



export function Footer() {

    const today = new Date();

    return (
        <Box sx={{width: '100%',textAlign: 'center', justifyContent: 'center', borderTop: 1, mt: 2, pb: 4}}>
        <footer>
            <Stack direction="column" alignItems="center" spacing={1}>

                <Stack direction="row" spacing={1}>
                    <Link href="/privacy-policy" sx={{ cursor: 'pointer' }} underline="none" variant="body2">Privacy Policy</Link>
                    <Link href="/terms-of-use" sx={{ cursor: 'pointer' }} underline="none" variant="body2">Terms of Use</Link>
                </Stack>

                <Typography variant="body2" gutterBottom>{"Contact: help@solvedsports.com"}</Typography>

                <Stack direction="row" spacing={1}>
                    {socialLinks.map((item, index) => (
                    <IconButton key={index} onClick={()=>window.open(item.link, '_blank')}>
                        {item.Icon}
                    </IconButton>
                    ))}
                    
                </Stack>

                <Box>
                    <Typography variant="body2">Data provided by Stats Perform LLC</Typography>
                </Box>

                <Typography variant="body" gutterBottom sx={{pt: 2}}>
                    &copy; {today.getFullYear()} Solved Sports LLC
                </Typography>
            </Stack>
        </footer>
        </Box>
        
    );

}