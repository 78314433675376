import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseHome = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            <strong>Last Revised: July 9, 2024</strong>
            <br />
            <br />
            Please read this agreement carefully.
            <br/>
            <br/>
            The following Terms and Conditions of Use constitute a binding agreement between you (“User”)
            and Solved Sports LLC (the “Company,” “Solved Sports LLC,” “we”, “us”, or “our”). By
            accessing or using Solved Sports LLC’s website, mobile application, or any other platforms
            associated with Solved Sports LLC, you agree to be bound by Solved Sports LLC’s Terms and
            Conditions of Use (the “Terms” or the “Agreement”), including Solved Sports LLC’s Privacy
            Policy.
            <br />
            <br />
            By accessing <a href='www.solvedsports.com'>www.solvedsports.com</a> (the “Website”) or any other platforms or services Solved
            Sports LLC may offer (collectively, the “Service” or our “Services”), you agree to, and are bound
            by this Agreement. This Agreement applies to anyone who accesses the Website or uses our
            Services, regardless of registration or subscription status.
        </Typography>
    </Box>
)

export default TermsOfUseHome;