import React, { useState } from 'react'; 
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PrivacyPolicyHome from './privacy-policy-sections/home';
import PrivacyPolicyInformationCollect from './privacy-policy-sections/info-collect';
import PrivacyPolicyCookies from './privacy-policy-sections/cookies';
import PrivacyPolicyInformationUsage from './privacy-policy-sections/info-use';
import PrivacyPolicyInformationAccess from './privacy-policy-sections/info-access';
import PrivacyPolicyRights from './privacy-policy-sections/rights';
import PrivacyPolicyInformationRetainment from './privacy-policy-sections/info-retain';
import PrivacyPolicyChanges from './privacy-policy-sections/changes';
import PrivacyPolicyCAResidents from './privacy-policy-sections/ca-residents';
import PrivacyPolicyContact from './privacy-policy-sections/contact';

const drawerWidth = 240;

export default function PrivacyPolicy() {
    const [selectedPage, setSelectedPage] = useState('home'); // default page
    const renderContent = () => {
        switch (selectedPage) {
            case 'home':
                return <PrivacyPolicyHome />;
            case 'info-collect':
                return <PrivacyPolicyInformationCollect />;
            case 'cookies':
                return <PrivacyPolicyCookies />;
            case 'info-usage':
                return <PrivacyPolicyInformationUsage />;
            case 'info-access':
                return <PrivacyPolicyInformationAccess />;
            case 'controls':
                return <PrivacyPolicyRights />;
            case 'info-retain':
                return <PrivacyPolicyInformationRetainment />;
            case 'changes':
                return <PrivacyPolicyChanges />;
            case 'ca-residents':
                return <PrivacyPolicyCAResidents />;
            case 'contact':
                return <PrivacyPolicyContact />;
            default:
                return <PrivacyPolicyHome />;
        }
    };
    
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        Solved Sports Privacy Policy
                    </Typography>
                    <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' }, marginLeft: '30px' }}>
                        <Button href="/">
                            Home
                        </Button>
                        <Button href="/privacy-policy">
                            Privacy Policy
                        </Button>
                        <Button href="/terms-of-use">
                            Terms Of Use
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {[
                            { text: 'Introduction', target: 'home' },
                            { text: 'Information We Collect', target: 'info-collect' },
                            { text: 'Cookies', target: 'cookies' },
                            { text: 'Information Usage', target: 'info-usage' },
                            { text: 'Information Access', target: 'info-access' },
                            { text: 'Controls, Choices and Rights', target: 'controls' },
                            { text: 'Information Retainment', target: 'info-retain' },
                            { text: 'Changes to Privacy Policy', target: 'changes' },
                            { text: 'California Residents', target: 'ca-residents' },
                            { text: 'Contact Us', target: 'contact' }
                        ].map((item, index) => (
                            <ListItem key={item.text}>
                                <ListItemButton onClick={() => setSelectedPage(item.target)}>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box
                component="main"
                sx={{
                //     flexGrow: 1,
                    p: 3,
                //     marginLeft: `${drawerWidth}px`,
                //     backgroundColor: '#f4f4f4', // example background color
                //     minHeight: '100vh',
                }}
            >
                <Toolbar />
                {renderContent()}
            </Box>
        </Box>
    );
}
