import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseCopyright = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Solved Sports LLC respects the intellectual property rights of others and expects Users to do the
            same. Solved Sports LLC users are prohibited from uploading any content that they do not have
            the right to post, including without limitation copyrighted material. Solved Sports LLC will
            cooperate and respond to notices of alleged copyright infringement that have a reasonable legal
            basis for the claim, as determined by legal counsel for Solved Sports LLC. Any infringing material
            discovered on the Website will be removed from the Website. Repeat infringers will not be
            tolerated.
            <br/>
            <br/>
            Solved Sports LLC is not liable for the infringement, copyright or otherwise, of its Users or any
            Third Parties.
            <br />
            <br />
            If you believe that your work has been copied in a way that constitutes copyright infringement,
            please provide Solved Sports LLC all of the written information specified below. Please note that
            this procedure is exclusively for notifying Solved Sports LLC that your copyrighted material has
            been infringed. Please include the following:
            <ol>
                <li>An electronic or physical signature of the person authorized to act on behalf of the owner
                    of the copyright interest;</li>
                <li>A description of the copyrighted work that you claim has been infringed;</li>
                <li>A description of where the material that you claim is infringing is located on the Site,
                    including the website address/URL;</li>
                <li>Your address, telephone number, and e-mail address;</li>
                <li>A statement that you have a good-faith belief that the disputed use is unauthorized by the
                    copyright owner, its agent, and/or the law;</li>
                <li>A statement by you, made under the penalty of perjury, that the above information in your
                    notice is accurate and that you are the copyright owner or authorized to act on the copyright
                    owner’s behalf.</li>
            </ol>
            Any copyright complaints can be submitted to: <a href='legal@solvedsports.com'>legal@solvedsports.com</a>.
        </Typography>
    </Box>
)

export default TermsOfUseCopyright;