import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const PrivacyPolicyCAResidents = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            If you are a resident of California, you are entitled to certain rights with respect to your personal
            information under the California Consumer Privacy Act (California Civil Code §§1798.100 -
            1798.199) (the “CCPA”).
            <br />
            <br />
            <u>Your Rights Under the CCPA</u>
            <br />
            <br />
            Right to Know: You have the right to request that we disclose what personal information we
            collect, use, disclose, and sell. This includes the categories or personal information collected, the
            categories of sources from which the information is collected, the business or commercial purpose
            for collecting or selling the information, and the categories of third parties with whom we share
            the information.
            <br />
            <br />
            Right to Delete: You have the right to request the deletion of your personal information that we
            have collected, subject to certain exceptions.
            <br />
            <br />
            Right to Opt-Out: You have the right to opt-out of the sale of your personal information.
            <br />
            <br />
            Right to Non-Discrimination: You have the right to not receive discriminatory treatment by us for
            exercising your rights under the CCPA. This means we will not deny you goods or services, charge
            you different prices or rates, or provide a different level or quality of goods or services unless the
            difference is reasonably related to the value of your personal information.
            <br />
            <br />
            <u>Exercising Your CCPA Rights</u>
            <br />
            <br />
            To exercise any of your CCPA rights, please contact us at the contact information provided at the
            end of this Privacy Policy.
        </Typography>
    </Box>
)

export default PrivacyPolicyCAResidents;