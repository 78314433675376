import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseMiscellaneous = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            Solved Sports LLC may assign, transfer, sell, or otherwise dispose of its rights and obligations
            under the Terms, in whole or in part, at any time, without notice. Users may not assign this
            agreement, or any part of it to another party or individual.
        </Typography>
    </Box>
)

export default TermsOfUseMiscellaneous;