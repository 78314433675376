import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseAssignment = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            You may not assign or transfer these Terms, by operation of law or otherwise, without Solved
            Sports LLC’s prior written consent. Any attempt by you to assign or transfer these Terms without
            such consent will be null and of no effect. Solved Sports LLC may assign these Terms, at its sole
            discretion, without restriction. Subject to the foregoing, these Terms will bind and inure to the
            benefits of the parties, their successors and permitted assignees. These Terms do not and are not
            intended to confer any rights or remedies upon any person other than the parties.
        </Typography>
    </Box>
)

export default TermsOfUseAssignment;