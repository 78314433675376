import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const TermsOfUseNoWaiver = () => (
    <Box sx={{ p: 5 }}>
        <Typography component="section" sx={{ marginTop: 2 }}>
            No waiver by Solved Sports LLC of any term or condition set out in these Terms shall be deemed
            a further or continuing waiver of such term or condition or a waiver of any other term or condition,
            and any failure of our Terms to assert a right or provision shall not constitute a waiver of such a
            right or provision.
        </Typography>
    </Box>
)

export default TermsOfUseNoWaiver;