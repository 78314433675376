import React, { useState } from 'react';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TermsOfUseHome from './terms-of-use-sections/home';
import TermsOfUseModification from './terms-of-use-sections/modification';
import TermsOfUsePrivacyPolicy from './terms-of-use-sections/privacy-policy';
import TermsOfUseLinks from './terms-of-use-sections/links-to-other-websites';
import TermsOfUseIPLL from './terms-of-use-sections/ip-and-ll';
import TermsOfUseAccountEligibility from './terms-of-use-sections/account-eligibility';
import TermsOfUseSubscriptions from './terms-of-use-sections/subscriptions';
import TermsOfUseContent from './terms-of-use-sections/content';
import TermsOfUseCopyright from './terms-of-use-sections/copyright';
import TermsOfUseIndemnification from './terms-of-use-sections/indemnification';
import TermsOfUseTermination from './terms-of-use-sections/terminating-and-monitoring';
import TermsOfUseWarranties from './terms-of-use-sections/warranties';
import TermsOfUseLimitedLiability from './terms-of-use-sections/limited-liability';
import TermsOfUseMiscellaneous from './terms-of-use-sections/miscellaneous';
import TermsOfUseNotice from './terms-of-use-sections/notice';
import TermsOfUseGoverningLaw from './terms-of-use-sections/governing-law';
import TermsOfUseNoWaiver from './terms-of-use-sections/no-waiver';
import TermsOfUseAssignment from './terms-of-use-sections/assignment';
import TermsOfUseAgreement from './terms-of-use-sections/agreement';
import TermsOfUseContact from './terms-of-use-sections/contact';

const drawerWidth = 240;

export default function PrivacyPolicy() {
    const [selectedPage, setSelectedPage] = useState('home'); // default page
    const renderContent = () => {
        switch (selectedPage) {
            case 'home':
                return <TermsOfUseHome/>
            case 'modification':
                return <TermsOfUseModification />
            case 'privacy-policy':
                return <TermsOfUsePrivacyPolicy />
            case 'eligibility':
                return <TermsOfUseAccountEligibility />
            case 'subscriptions':
                return <TermsOfUseSubscriptions />
            case 'content':
                return <TermsOfUseContent />
            case 'links-to-other-sites':
                return <TermsOfUseLinks />
            case 'ip-ll':
                return <TermsOfUseIPLL />
            case 'copyright':
                return <TermsOfUseCopyright />
            case 'indemnification':
                return <TermsOfUseIndemnification />
            case 'term-and-mon':
                return <TermsOfUseTermination />
            case 'warranties':
                return <TermsOfUseWarranties />
            case 'limitations':
                return <TermsOfUseLimitedLiability />
            case 'misc':
                return <TermsOfUseMiscellaneous />
            case 'notice':
                return <TermsOfUseNotice />
            case 'gov-law':
                return <TermsOfUseGoverningLaw />
            case 'no-waiver':
                return <TermsOfUseNoWaiver />
            case 'assignment':
                return <TermsOfUseAssignment />
            case 'agreement':
                return <TermsOfUseAgreement />
            case 'contact':
                return <TermsOfUseContact />
            default:
                return <TermsOfUseHome />
        }
    };
    const pages = ['Privacy Policy', 'Terms Of Use'];
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        Solved Sports Terms of Use
                    </Typography>
                    <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' }, marginLeft: '30px' }}>
                        <Button href="/">
                            Home
                        </Button>
                        <Button href="/privacy-policy">
                            Privacy Policy
                        </Button>
                        <Button href="/terms-of-use">
                            Terms Of Use
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {[
                            { text: 'Introduction', target: 'home' },
                            { text: 'Modification', target: 'modification' },
                            { text: 'Privacy Policy', target: 'privacy-policy' },
                            { text: 'Account Eligibility', target: 'eligibility' },
                            { text: 'Subscriptions', target: 'subscriptions' },
                            { text: 'Content', target: 'content' },
                            { text: 'Links to Other Websites', target: 'links-to-other-sites' },
                            { text: 'Intellectual Property and Limited License', target: 'ip-ll' },
                            { text: 'Copyright Policy', target: 'copyright' },
                            { text: 'Indemnification', target: 'indemnification' },
                            { text: 'Terminating and Monitoring', target: 'term-and-mon' },
                            { text: 'Warranties', target: 'warranties' },
                            { text: 'Limitation of Liability', target: 'limitations' },
                            { text: 'Miscellaneous', target: 'misc' },
                            { text: 'Notice', target: 'notice' },
                            { text: 'Governing Law', target: 'gov-law' },
                            { text: 'No Waiver', target: 'no-waiver' },
                            { text: 'Assignment', target: 'assignment' },
                            { text: 'Entire Agreement', target: 'agreement' },
                            { text: 'Contact Us', target: 'contact' },
                            
                        ].map((item, index) => (
                            <ListItem key={item.text}>
                                <ListItemButton onClick={() => setSelectedPage(item.target)}>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box
                component="main"
                sx={{
                    p: 3,
                }}
            >
                <Toolbar />
                {renderContent()}
            </Box>
        </Box>
    );
}
